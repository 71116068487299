<template>
  <main class="container">
    <br />

    <div class="row">
      <div class="card rounded-lg border border-dark">
        <div class="row">
          <div class="row my-5 mx-auto">
            <img src="@/assets/result_htm_400e0a29605d652f.png">
<table cellspacing="0" border="0">
	<colgroup width="95"></colgroup>
	<colgroup width="592"></colgroup>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		</tr>
	<tr>
		<td height="19" align="left" valign=bottom><font color="#000000"><br></font></td>
		<td align="left" valign=bottom><font color="#000000"><br></font></td>
	</tr>
	<tr>
		<td height="19" align="left" valign=bottom><font color="#000000"><br></font></td>
		<td align="left" valign=bottom><font color="#000000"><br></font></td>
	</tr>
	<tr>
		<td colspan=2 height="34" align="center" valign=bottom><b><i><font size=5 color="#000000">SYMPOSIUM PROGRAM</font></i></b></td>
		</tr>
		<tr>
		<td colspan=2 height="34" align="center" valign=bottom><b><i><font size=5 color="#000000">YOUTUBE Live Stream: <a href='https://www.youtube.com/channel/UCYMKPXnIn3a58h_IFVWUWlg'>METEO IRS'2021</a>
</font></i></b></td>
		</tr>
	<tr>
		<td height="34" align="center" valign=bottom><b><i><font size=5 color="#000000"><br></font></i></b></td>
		<td align="center" valign=bottom><font color="#000000"><br></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#FFC000" sdnum="1033;0;@"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=bottom bgcolor="#FFC000" sdnum="1033;0;@"><b><font color="#000000">1 DECEMBER 2021, WEDNESDAY</font></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000"> OPENING SESSION (in Turkish)</font></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=6 height="115" align="center" valign=middle><i><font color="#000000">10:00 - 10:45</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">AHMET &Ouml;ZTOPAL, Chair of METEO IRS 2021</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">AHMET DURAN &#350;AH&#304;N, Chair, ITU Department of Meteorological Engineering </font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">EMEL &Uuml;NAL, General Secretary, TMMOB Chamber of Meteorology Engineers</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">MERAL DEM&#304;RTA&#350;, Chair, SAMU Department of Meteorological Engineering</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">ZAH&#304;T MEC&#304;TO&#286;LU, Dean, ITU Faculty of Aeronautics and Astronautics</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">VOLKAN MUTLU CO&#350;KUN, General Manager, Turkish State Meteorological Service</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000">SESSION 1</font></b></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><i><font color="#000000">CHAIR: EMRAH TUNCAY &Ouml;ZDEM&#304;R</font></i></b></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="77" align="center" valign=middle><i><font color="#000000">11:00 - 11:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">OPERATIONAL  WEATHER  RADAR  IN  THE  UNITED  STATES  AND  CANADA:  PAST,  PRESENT  AND FUTURE</font></i></td>
	</tr>
	<tr>
		<td style="border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">SAMUEL MILLER, Invited Speaker</font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">Plymouth State University, NH, USA</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="38" align="center" valign=middle><i><font color="#000000">11:40 - 12:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">MU&#286;LA RADARI YA&#286;I&#350; TAHM&#304;N HATALARININ L2 VE L&infin; NORMLARIYLA ANAL&#304;Z&#304;</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">ARZU &Ouml;ZKAYA, A. EGEMEN YILMAZ</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="38" align="center" valign=middle><i><font color="#000000">12:00 - 12:20</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">RADAR VER&#304;LER&#304; &Uuml;ZER&#304;NDEN MEVS&#304;ML&#304;K &#304;STANBUL YA&#286;I&#350; DESENLER&#304;N&#304;N ELDE ED&#304;LMES&#304;</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">EM&#304;R YAPICI, AHMET &Ouml;ZTOPAL</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="58" align="center" valign=middle><i><font color="#000000">12:20 - 12:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">&Ouml;RNEK B&#304;R VER&#304; SET&#304; &Uuml;ZER&#304;NDE K&Uuml;RESEL YA&#286;I&#350; &Ouml;L&Ccedil;&Uuml;M&Uuml; (GPM) G&Ouml;REV&#304; VE METEOROLOJ&#304; &#304;STASYONLARI VER&#304;LER&#304;N&#304;N DE&#286;ERLEND&#304;R&#304;LMES&#304;</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">MUSTAFA H&Uuml;SREVO&#286;LU, &#304;SMA&#304;L B&Uuml;LENT G&Uuml;NDO&#286;DU</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="38" align="center" valign=middle><i><font color="#000000">12:40 - 13:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">METRAD S&#304;STEM&#304; YA&#286;I&#350; ALGOR&#304;TMALARI VE GELECE&#286;&#304;N RADARI METRAD-3</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">EMRE &Ccedil;&#304;FT&Ccedil;&#304;BA&#350;I, Y&Uuml;CEL &Ouml;ZDEM&#304;R, ERGENEKON HASSOY</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000">13:00 - 14:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#000000">LUNCH</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td colspan=2 height="19" align="center" valign=bottom><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000">SESSION 2</font></b></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><i><font color="#000000">CHAIR: ZEREF&#350;AN KAYMAZ</font></i></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="58" align="center" valign=middle><i><font color="#000000">14:00 - 14:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">USE OF ARTIFICIAL INTELLIGENCE FOR PRECIPITATION REMOTE SENSING FROM SATELLITE</font></i></td>
	</tr>
	<tr>
		<td style="border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">DANIELE CASELLA, Invited Speaker</font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">National Research Council of Italy, Roma, ITALY</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="58" align="center" valign=middle><i><font color="#000000">14:40 - 15:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">A CASE STUDY ANALYSIS OF WILDFIRES BY REMOTE SENSING AND COPERNICUS EFFIS APPLICATIONS: THE SUMMER 2021 MANAVGAT, ANTALYA FOREST FIRES</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">SEMA &Ccedil;&#304;L, OZAN P&#304;RBUDAK</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="58" align="center" valign=middle><i><font color="#000000">15:00 - 15:20</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">UZAKTAN ALGILAMA VE METEOROLOJ&#304;K VER&#304;LERDEN ELDE ED&#304;LEN KURAKLIK &#304;ND&#304;SLER&#304;N&#304;N ZAMAN-FREKANS ANAL&#304;Z&#304;</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">SEMRA KOCAASLAN, NEB&#304;YE MUSAO&#286;LU</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000">15:20 - 15:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#000000"> COFFEE BREAK</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000">SESSION 3</font></b></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><i><font color="#000000">CHAIR: BAHT&#304;YAR EFE</font></i></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="58" align="center" valign=middle><i><font color="#000000">15:40 - 16:20</font></i></td>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">USING A SMALL X-BAND RADAR TO FILL A GAP IN A NATIONAL WEATHER RADAR NETWORK</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">NEIL I. FOX,  Invited Speaker</font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">University of Missouri, USA</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="58" align="center" valign=middle><i><font color="#000000">16:20 - 17:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=bottom><font color="#0000FF">LANDSAT-5 TM VER&#304;LER&#304; &#304;LE SU KAL&#304;TES&#304; ARA&#350;TIRMASI: MINNESOTA (ABD) &Ouml;RNE&#286;&#304;</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">SEM&#304;H EKERC&#304;N, Invited Speaker</font></i></td>
	</tr>
	<tr>
		<td style="border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">Necmettin Erbakan University, TURKEY</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000"><br></font></i></td>
		<td style="border-top: 1px solid #000000" align="center" valign=middle><i><font color="#000000"><br></font></i></td>
	</tr>
	<tr>
		<td height="19" align="center" valign=middle><i><font color="#000000"><br></font></i></td>
		<td align="center" valign=middle><i><font color="#000000"><br></font></i></td>
	</tr>
	<tr>
		<td height="19" align="center" valign=middle><i><font color="#000000"><br></font></i></td>
		<td align="center" valign=middle><i><font color="#000000"><br></font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000"><br></font></i></td>
		<td style="border-bottom: 1px solid #000000" align="center" valign=middle><i><font color="#000000"><br></font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#FFC000" sdnum="1033;0;@"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#FFC000" sdnum="1033;0;@"><b><font color="#000000">2 DECEMBER 2021, THURSDAY</font></b></td>
	</tr>
	<tr>
		<td style="border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000">SESSION 4</font></b></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><i><font color="#000000">CHAIR: HAKAN AKSU</font></i></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="77" align="center" valign=middle><i><font color="#000000">10:00 - 10:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">REMOTE SENSING ACTIVITIES FOCUSING ON CRYOSPHERE APPLICATIONS AT THE FINNISH METEOROLOGICAL INSTITUTE</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">AL&#304; NAD&#304;R ARSLAN, Invited Speaker</font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">Finnish Meteorological Institute, FINLAND</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="77" align="center" valign=middle><i><font color="#000000">10:40 - 11:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">CROP WATER STRESS INDEX ESTIMATION BY REMOTE SENSING IN THE MEDITERRENEAN REGION OF TURKEY</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">MAHMUT   &Ccedil;ET&#304;N,  HAKAN  AKSU,  OMAR  ALSENJAR,  MUHAMMET  SAID  GOLPINAR,  MEHMET AL&#304; AKG&Uuml;L</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="38" align="center" valign=middle><i><font color="#000000">11:00 - 11:20</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#0000FF">T&Uuml;RK&#304;YE UYDU KAYNAKLI BULUT ATLASI PROJES&#304;</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">AHMET &Ouml;ZTOPAL, HAKAN KO&Ccedil;AK, ERDEM ERD&#304;, REMZ&#304; AKY&Uuml;Z, MELEK AKIN, MURAT ARSLAN</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000">11:20 - 11:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#000000"> COFFEE BREAK</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000">SESSION 5</font></b></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><i><font color="#000000">CHAIR: SEV&#304;N&Ccedil; AS&#304;LHAN</font></i></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="58" align="center" valign=middle><i><font color="#000000">11:40 - 12:20</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=bottom><i><font color="#0000FF">HAIL FROM SPACE</font></i></td>
	</tr>
	<tr>
		<td style="border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">SANTE LAVIOLA, Invited Speaker</font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">National Research Council of Italy, Bologna, ITALY</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="96" align="center" valign=middle><i><font color="#000000">12:20 - 12:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">USE OF A H-SAF PRODUCTS SELECTION FOR PRECIPITATION ANALYSIS: <br>VALIDATION, INTER-COMPARISON AND CASE STUDIES</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">MARCO PETRACCA, ALESSANDRA MASCITELLI, JAN KANAK, &#317;UBOSLAV OKON, MARTA DIOSZEGHY, PIERRE BAGUIS, ERAM ARTINYAN, BOZENALAPETA, AHMET &Ouml;ZTOPAL, PETER KRAHE, FEDERICO PORC&Ugrave;, SILVIA PUCA</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="38" align="center" valign=middle><i><font color="#000000">12:40 - 13:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">PAS&#304;F M&#304;KRODALGA VER&#304;S&#304; TABANLI B&#304;R YA&#286;I&#350; ALGOR&#304;TMASI GEL&#304;&#350;T&#304;R&#304;LMES&#304;</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">MELEK AKIN, AHMET &Ouml;ZTOPAL</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000">13:00 - 14:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#000000">LUNCH</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000">SESSION 6</font></b></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><i><font color="#000000">CHAIR: EL&Ccedil;&#304;N TAN</font></i></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="77" align="center" valign=middle><i><font color="#000000">14:00 - 14:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">UYDUDAN &#304;NSANSIZ HAVA ARA&Ccedil;LARI'NA (&#304;HA) KAR PARAMETRELER&#304;N&#304;N BEL&#304;RLENMES&#304;NDEK&#304; GEL&#304;&#350;MELER</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">ZUHAL AKY&Uuml;REK, Invited Speaker</font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">Middle East Technical University, TURKEY</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="58" align="center" valign=middle><i><font color="#000000">14:40 - 15:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">A COMPARATIVE STUDY ON THE PERFORMANCE OF THE GRACE-BASED WSDI AND GDSI INDICES FOR DROUGHT SURVEILLANCE OVER TURKEY</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">BEHNAM KHORRAMI, ORHAN G&Uuml;ND&Uuml;Z</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="38" align="center" valign=middle><i><font color="#000000">15:00 - 15:20</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#0000FF">A GEOSPATIAL APPROACHES: DROUGHT MONITORING USING GOOGLE EARTH ENGINE</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">VENKADESH SAMYKANNU, SELLAPERUMAL PAZHANIVELAN</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000">15:20 - 15:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#000000"> COFFEE BREAK</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000">SESSION 7</font></b></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><i><font color="#000000">CHAIR: B&#304;HTER DURNA</font></i></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="58" align="center" valign=middle><i><font color="#000000">15:40 - 16:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#0000FF">T&Uuml;RK ARKT&#304;K B&#304;L&#304;MSEL SEFERLER&#304; KAPSAMINDA GSM VE UYDU VER&#304;LER&#304;NDEN ELDE ED&#304;LEN ATMOSFER&#304;K PART&#304;K&Uuml;L VER&#304;LER&#304;N&#304;N ANAL&#304;Z&#304; VE S&#304;STEM TASARIMI</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">FURKAN AL&#304; K&Uuml;&Ccedil;&Uuml;K, CEYHAN KAHYA</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="58" align="center" valign=middle><i><font color="#000000">16:00 - 16:20</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#0000FF">ORMAN YANGINLARINDAN KAYNAKLI HAVA K&#304;RL&#304;L&#304;&#286;&#304;N&#304;N UYDU G&Ouml;ZLEMLER&#304;YLE BEL&#304;RLENMES&#304;</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">EFEM B&#304;LG&#304;&Ccedil;, G&#304;ZEM TUNA TUYGUN, ORHAN G&Uuml;ND&Uuml;Z</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="58" align="center" valign=middle><i><font color="#000000">16:20 - 16:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">10-11 A&#286;USTOS 2021 TAR&#304;HLER&#304;NDE BARTIN, KASTAMONU VE S&#304;NOP&rsquo;TA MEYDANA GELEN SEL HAD&#304;SELER&#304;N&#304;N METEOROLOJ&#304;K ANAL&#304;Z&#304;</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">EMRAH TUNCAY &Ouml;ZDEM&#304;R, BAHT&#304;YAR EFE, AL&#304; DEN&#304;Z</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="77" align="center" valign=middle><i><font color="#000000">16:40 - 17:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#0000FF">DIGITIZING THE BURNED-OUT AREAS IN THE 2021 ANTALYA WILDFIRE WITH REMOTE SENSING METHODS</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">CEMA D&#304;LGE OHR&#304;L&#304;, AY&#350;E BURCU ATALAR, EM&#304;RHAN CEMEK, EL&#304;Z &Ouml;Z&Ouml;N, RANA BET&Uuml;L DA&#286;, MELEK CANDAN &#304;PEK, EL&Ccedil;&#304;N TAN, SEV&#304;N&Ccedil; AS&#304;LHAN</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000"><br></font></i></td>
		<td style="border-top: 1px solid #000000" align="center" valign=middle><i><font color="#000000"><br></font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000"><br></font></i></td>
		<td style="border-bottom: 1px solid #000000" align="center" valign=middle><i><font color="#000000"><br></font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#FFC000" sdnum="1033;0;@"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#FFC000" sdnum="1033;0;@"><b><font color="#000000">3 DECEMBER 2021, FRIDAY</font></b></td>
	</tr>
	<tr>
		<td style="border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000">SESSION 8</font></b></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><i><font color="#000000">CHAIR: KURTULU&#350; &Ouml;ZT&Uuml;RK</font></i></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="77" align="center" valign=middle><i><font color="#000000">10:00 - 10:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">Y&Uuml;KSEK &Ccedil;&Ouml;Z&Uuml;N&Uuml;RL&Uuml;KL&Uuml; UYDU TABANLI DEN&#304;Z SEV&#304;YE SICAKLIKLARININ EKSTREM YA&#286;I&#350; VE AKI&#350; TAHM&#304;NLER&#304;NE ETK&#304;S&#304;</font></i></td>
	</tr>
	<tr>
		<td style="border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">&#304;SMA&#304;L Y&Uuml;CEL, Invited Speaker</font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">Middle East Technical University, TURKEY</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="58" align="center" valign=middle><i><font color="#000000">10:40 - 11:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">ANTALYA K&Ouml;RFEZ&#304;&rsquo;NDE EKSTREM DEN&#304;Z SUYU SICAKLIKLARININ UZUN D&Ouml;NEML&#304; DE&#286;&#304;&#350;&#304;M&#304;N&#304;N MEVS&#304;MSEL &#304;NCELENMES&#304;</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">FEVZ&#304;YE AY&Ccedil;A SARA&Ccedil;O&#286;LU, BERNA AYAT, BURAK AYDO&#286;AN, KEB&#304;R EMRE SARA&Ccedil;O&#286;LU</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="38" align="center" valign=middle><i><font color="#000000">11:00 - 11:20</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">SEBAL Y&Ouml;NTEM&#304; &#304;LE UYDU TABANLI EVAPOTRANSP&#304;RASYON HESAPLANMASI</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">KER&#304;M AT&#304;LLA KORKMAZ</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000">11:20 - 11:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#000000"> COFFEE BREAK</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000">SESSION 9</font></b></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><i><font color="#000000">CHAIR: CEYHAN KAHYA</font></i></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="58" align="center" valign=middle><i><font color="#000000">11:40 - 12:20</font></i></td>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">UYDU &Uuml;R&Uuml;NLER&#304;N&#304;N SU KAYNAKLARI PLANLAMASI VE Y&Ouml;NET&#304;M&#304;NDE KULLANIMI</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">AYNUR &#350;ENSOY &#350;ORMAN, Invited Speaker</font></i></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">Eski&#351;ehir Technical University, TURKEY</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="58" align="center" valign=middle><i><font color="#000000">12:20 - 12:40</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">STATISTICAL ASSESSMENT AND HYDROLOGICAL UTILITY OF MULTIPLE GRIDDED<br>PRECIPITATION DATASETS IN ARAS BASIN</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">HAMED HAFIZI,  AL&#304; ARDA &#350;ORMAN</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="38" align="center" valign=middle><i><font color="#000000">12:40 - 13:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">ORMAN YANGINLARININ MSG UYDUSUNDAN TESP&#304;T&#304;</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">F&#304;GEN &Ouml;ZCAN, AHMET &Ouml;ZTOPAL</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="38" align="center" valign=middle><i><font color="#000000">13:00 - 13:20</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#0000FF">YEN&#304;L&#304;K&Ccedil;&#304; TREND ANAL&#304;Z&#304; Y&Ouml;NTEM&#304;YLE G&Uuml;NE&#350; ENERJ&#304;S&#304; POTANS&#304;YEL&#304;N&#304;N &#304;NCELENMES&#304;</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">N&#304;HAT MERT YAL&Ccedil;INKAYA, AHMET &Ouml;ZTOPAL</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000">13:00 - 14:00</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><font color="#000000">LUNCH</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000" height="19" align="left" valign=bottom bgcolor="#D9D9D9"><b><font color="#000000"><br></font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#D9D9D9"><b><font color="#000000"> CLOSING SESSION</font></b></td>
	</tr>
	<tr>
		<td style="border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="center" valign=middle><i><font color="#000000">14:00 - 14:45</font></i></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><i><font color="#000000">CLOSING TALKS</font></i></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan=2 height="19" align="center" valign=bottom bgcolor="#8EB4E3"><font color="#000000"><br></font></td>
		</tr>
</table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style></style>